import React from "react";
import { shape } from "prop-types";
import { graphql } from 'gatsby'
import Layout from "../components/Layout";
import Sections from "../contentful/Sections";
import Usps from "../contentful/Usps";

const index = ({data}) => {
    return (
      <Layout>
        <Sections layouts={data.contentfulPageContainer.layouts} />
        {
          data.allContentfulLayoutUspsItems.edges.map(edge => (
            <Usps items={edge.node.items} />
            
          ))
        }
      </Layout>
    );
};
index.defaultProps = {
  data: {}
};

index.propTypes = {
  data: shape()
};

export default index

export const homeQuery = graphql`
         query homeQuery {
           allContentfulLayoutUspsItems(filter: { slug: { eq: "home" } }) {
             edges {
               ...uspsFragment
             }
           }
           contentfulPageContainer(slug: { eq: "home" }) {
             layouts {
               ... on ContentfulLayoutNameDescription {
                 ...portfolioDescriptionFragment
               }
               ... on ContentfulLayoutProjectDescription {
                 id
                 company
                 description
                 header
                 title
                 sys {
                   contentType {
                     sys {
                       contentful_id
                     }
                   }
                 }
                 image {
                   fluid(
                     maxWidth: 450
                     maxHeight: 570
                     resizingBehavior: FILL
                     quality: 70
                   ) {
                     sizes
                     aspectRatio
                     src
                     srcSet
                     srcWebp
                     srcSetWebp
                   }
                 }
                 projectDescription {
                   projectDescription
                   childMarkdownRemark {
                     rawMarkdownBody
                     html
                   }
                 }
               }
             }
           }
         }
       `;

/* import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import Hero from '../components/hero'
import Layout from '../components/layout'
import ArticlePreview from '../components/article-preview'

class RootIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout location={this.props.location}>
        <div style={{ background: '#fff' }}>
          <Helmet title={siteTitle} />
          <Hero data={author.node} />
          <div className="wrapper">
            <h2 className="section-headline">Recent articles</h2>
            <ul className="article-list">
              {posts.map(({ node }) => {
                return (
                  <li key={node.slug}>
                    <ArticlePreview article={node} />
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
 */